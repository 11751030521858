
.bannerImg { 
    margin-left: 'auto';
    margin-right: 'auto';
    margin-top: 5em;
    width: 100%;
}
.smallLandingCard {
    color: #0B0346;
    margin-top: 2rem;
    min-width: fit-content;
}
.summaryCard {
   text-align: center;
    
}
.serviceCard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    
}
.footerItems {
    margin-top: 10em;
}
.Carousel__Container-sc-hyhecw-0 {
    width: 100%;
    padding: 0px;
    margin: 0px;
}
.footerLogo{
    margin-top: 1rem;
    width: 5rem;
    height: 5rem;
}
.requestQuote{
    margin-top: 2rem;
    background-color: #0B0346;
    color: white;
    border-radius: 10px;
    padding: .5em;
}
.requestQuote:hover{
    text-decoration: underline;
    background-color: lightgray;
    color: #0B0346;
}